import {
  Box,
  IconButton,
  TableContainer,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import CommanTable from "../../components/Table"
import { OnlineColumnData, OnlineRows, columns, rows } from "./helper"
import { SVG } from "../../icon/svg.file"
import { Link, useParams } from "react-router-dom"
import * as global from "../../constant/global"
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest"
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest"
import { getDateWithTimeFormat } from "../../utility-files/date-util/DateHandling"
import CustomLoader from "../../CustomLoader/CustomLoader"
import { useDispatch, useSelector } from "react-redux"
import action from "../../new redux/Action"
import constants from "../../new redux/Types/actionTypes"
import { isIntegerValue } from "../../utility-files/data-util/DataHandler"
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling"
import moment from "moment"
import { useTranslation } from "react-i18next"
import MobileTable from "../../components/Table/mobile.table"
import { toast } from "react-toastify"

export default function BillBoard() {
  const matches = useMediaQuery("(max-width:768px)")
  const { t } = useTranslation()
  const [tabs, setTabs] = useState(1)
  const [pending, setPending] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  })
  const [OfflineBillBoardStatus, setOfflineBillBoardStatus] = useState(false)
  const billBoardData = useSelector((state) => state.billBoardData)
  const [updatedbillBoardData, setupdatedbillBoardData] = useState([])
  const url = new URL(window.location.href)
  const id = url.searchParams.get("id")
  const dispatch = useDispatch()

  const countryName =
    localStorage.getItem(StorageHandling.storageKey.USERDATA) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERDATA)

  const handleTabs = (id) => {
    setPagination((prev) => ({ ...prev, page: 1 }))
    setTabs(id)
  }

  useEffect(() => {
    if (id) {
      setTabs(parseInt(id))
    }
  }, [id])

  useEffect(() => {
    // if (
    //   tabs === 2 &&
    //   (OfflineBillBoardStatus === true || OfflineBillBoardStatus === "running")
    // ) {
    //   return
    // }
    setOfflineBillBoardStatus(false)
    getAllBillBorad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, pagination.limit, pagination.page])

  // useEffect(() => {
  //   if (tabs === 1) getOfflineBillBorad()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tabs])

  const getAllBillBorad = async () => {
    // if (pending) return
    setPending(true)
    let requset, variables
    requset = getRequestForApi(
      `${global.GET_ALL_BILLBOARD}?page=${pagination.page || 1}&limit=${
        pagination.limit
      }&fields=country,status,address,dbStatus,billboardName,contactName,installationDate,ADSAmount,spots_per_Ad_per_day${
        tabs === 2 ? `&status=offline` : `${tabs === 3 ? "&status=online" : ""}`
      }`,
      variables,
      methodType.GET
    );
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          // console.log("[BillBoard] <getAllBillBorad>response", response)
          dispatch(action(constants.billBoardData, response?.data))
          setPagination((prev) => ({ ...prev, ...response?.data?.pagination }))
          setPending(false)
        }
      })
      .catch((err) => {
        setPending(false)
      })
  }

  const getOfflineBillBorad = async () => {
    setOfflineBillBoardStatus("running")
    let requset, variables
    requset = getRequestForApi(
      `${global.GET_ALL_BILLBOARD}?page=${pagination.page || 1}&limit=${
        pagination.limit
      }&fields=country,status,dbStatus,billboardName,contactName,installationDate,ADSAmount,spots_per_Ad_per_day&status=offline`,
      variables,
      methodType.GET
    )
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          // console.log("[BillBoard] <getAllBillBorad>response", response)
          dispatch(action(constants.billBoardData, response?.data))
          setPagination((prev) => ({ ...prev, ...response?.data?.pagination }))
          setOfflineBillBoardStatus(true)
        }
      })
      .catch((err) => {
        setOfflineBillBoardStatus(false)
      })
  }

  const userTabs = [
    {
      id: 1,
      label: t("Billboard_main.Billboards.tabs.all"),
    },
    {
      id: 2,
      label: t("Billboard_main.Billboards.tabs.offline"),
    },
    {
      id: 3,
      label: t("Billboard_main.Billboards.tabs.online"),
    },
  ]

  const AllDataColumn = [
    {
      id: 1,
      name: t("Billboard_main.Billboards.tabs.billboard_address"),
      key: "billboard_address",
    },
    {
      id: 2,
      name: t("Billboard_main.Billboards.tabs.owner"),
      key: "owner",
    },
    {
      id: 3,
      name: t("Ad_index.commonTable.columns.status"),
      key: "status",
    },
    {
      id: 4,
      name: t("Billboard_main.Billboards.tabs.installation"),
      key: "installation",
    },
    {
      id: 5,
      name: t("Billboard_main.Billboards.tabs.ads_amount"),
      key: "ads_amount",
    },
    {
      id: 6,
      name: t("Billboard_main.Billboards.tabs.ads_exposures"),
      key: "ads_exposures",
    },
    {
      id: 7,
      name: t("Ad_index.commonTable.columns.actions"),
      key: "action",
    },
  ]
  const AllData = Array.isArray(billBoardData?.data?.docs)
    ? billBoardData?.data?.docs.filter(
        (item) => item.country === `${countryName}`
      )
    : []

  useEffect(() => {
    getLinkedTickets_OfflineStarted()
  }, [billBoardData])

  const getLinkedTickets_OfflineStarted = async () => {
    if (AllData.length > 0) {
      const linkedTicketsPromise = AllData?.filter((item) => {
        const billStatus = item.status
          ? item.status
          : item.dbStatus
          ? "online"
          : "offline"
        return billStatus === "offline"
      }).map(async (billboard) => {
        const request = getRequestForApi(
          `${global.GET_ALL_ROOMS}?filter=approve&&billboard=${
            billboard?.billboardName || ""
          }`,
          "GET"
        )

        const response = await callHttpRequest(request)
        return response.data.result
      })

      // Geting the offline start date
      // const offlineStartedList = AllData?.filter((item)=>item.status ==="offline").map(async(billboard)=>{
      //    const request = getRequestForApi(
      //           `${global.GET_LOGS}${
      //            billboard?.billboardName || ""
      //           }`,
      //           "GET"
      //         );
      //         const response = await callHttpRequest(request);
      //         return response
      // })
      const linkedTickets = await Promise.all(linkedTicketsPromise).catch(
        (err) => {
          console.error(err)
          toast.error("Error fetching Linked tickets")
        }
      )

      //  const offlineLogs = await Promise.all(offlineStartedList).catch(
      //    (err) => {
      //      console.error(err);
      //      toast.error("Error fetching offline logs");
      //    }
      //  );

      const updatedData = AllData.map((item, index) => ({
        ...item,
        linkedTickets: linkedTickets[index],
      }))
      setupdatedbillBoardData(updatedData)
      // console.log("[Billboard]<getLinkedTickets_OfflineStarted>", offlineLogs);
    }
  }

  const AllRowData = Array.isArray(AllData)
    ? AllData.map((index) => {
        const billStatus = index.status
          ? index.status
          : index.dbStatus
          ? "online"
          : "offline"
        return {
          billboard_address: (
            <Link
              to={`/billboard/details?id=1`}
              style={{ color: "#fff", textDecoration: "none" }}
              onClick={() =>
                dispatch(action(constants.billboardName, index?.billboardName))
              }
            >
              {index?.address || "No data found"}
            </Link>
          ),
          owner: index.contactName || "No data found",
          installation: getDateWithTimeFormat(
            index.installationDate || "Invaliad Date"
          ),
          status:
            billStatus === "offline" ? (
              <Box
                component={"span"}
                sx={{ color: "#F01F1F", fontWeight: "600" }}
              >
                Offline
              </Box>
            ) : (
              <Box
                component={"span"}
                sx={{ color: "#349734", fontWeight: "600" }}
              >
                Online
              </Box>
            ),

          ads_amount: index?.ADSAmount?isIntegerValue(index?.ADSAmount):"N/A",

          ads_exposures: (() => {
            if (isIntegerValue(index.ADSAmount)) {
              const lastSpot =
                index?.spots_per_Ad_per_day[
                  index?.spots_per_Ad_per_day?.length - 1
                ];
              const lastSpotDate = moment(lastSpot?.date);
              const yesterday = new Date();
              yesterday.setDate(yesterday?.getDate() - 1);
              /**
               * Compare only date but not the time
               */
              if (moment(yesterday).isSame(lastSpotDate, "day")) {
                // console.log("<BillBoard> tooltip index", index);
                const toolTipTitle = index?.activeAds
                  ?.map(
                    (ad) =>
                      `${ad?.title} - ${Math.round(ad?.totalAdexposure)} \t`
                  )
                  .join(" + ");
                return (
                  <Tooltip title={toolTipTitle} arrow>
                    {index?.activeAds
                      ? index?.activeAds.reduce(
                          (acc, curr) =>
                            acc + Math.round(curr?.totalAdexposure),
                          0
                        )
                      : t("Billboard_index.typography.no_data_available")}
                  </Tooltip>
                );
              }
            }
            return 0;
          })(),
          action: (
            <IconButton
              component={Link}
              to={`/billboard/details?id=1`}
              disableRipple
              sx={{
                background: "#322E27",
                borderRadius: "10px",
                width: "30px",
                height: "30px",
              }}
              onClick={() =>
                dispatch(action(constants.billboardName, index?.billboardName))
              }
            >
              <SVG.EyeIcon />
            </IconButton>
          ),
        };
      })
    : []

  const OnlineColumnData = [
    {
      id: 1,
      name: t("Billboard_main.Billboards.tabs.billboard_address"),
      key: "billboard_address",
    },
    {
      id: 2,
      name: t("Billboard_main.Billboards.tabs.owner"),
      key: "owner",
    },

    {
      id: 3,
      name: t("Billboard_main.Billboards.tabs.installation"),
      key: "installation",
    },
    {
      id: 4,
      name: t("Billboard_main.Billboards.tabs.ads_amount"),
      key: "ads_amount",
    },
    {
      id: 5,
      name: t("Billboard_main.Billboards.tabs.ads_exposures"),
      key: "ads_exposures",
    },
    {
      id: 6,
      name: t("Ad_index.commonTable.columns.actions"),
      key: "action",
    },
  ]

  const OnlineRows = Array.isArray(AllData)
    ? AllData.filter((item) => {
        const billStatus = item.status
          ? item.status
          : item.dbStatus
          ? "online"
          : "offline"
        return billStatus === "online"
      }).map((index) => {
        return {
          billboard_address: index?.address || "No data found",
          owner: index.contactName || "No data found",
          installation: getDateWithTimeFormat(
            index.installationDate || "Invalid Date"
          ),
          ads_amount: index?.ADSAmount?isIntegerValue(index.ADSAmount):"N/A",
          ads_exposures: (() => {
            if (isIntegerValue(index.ADSAmount)) {
              const lastSpot =
                index?.spots_per_Ad_per_day[
                  index?.spots_per_Ad_per_day?.length - 1
                ];
              const lastSpotDate = moment(lastSpot?.date);
              const yesterday = new Date();
              yesterday.setDate(yesterday?.getDate() - 1);
              /**
               * Compare only date but not the time
               */
              if (moment(yesterday).isSame(lastSpotDate, "day")) {
                // console.log("<BillBoard> tooltip index", index);
                const toolTipTitle = index?.activeAds
                  ?.map(
                    (ad) =>
                      `${ad?.title} - ${Math.round(ad?.totalAdexposure)} \t`
                  )
                  .join(" + ");
                return (
                  <Tooltip title={toolTipTitle} arrow>
                    {index?.activeAds
                      ? index?.activeAds.reduce(
                          (acc, curr) =>
                            acc + Math.round(curr?.totalAdexposure),
                          0
                        )
                      : t("Billboard_index.typography.no_data_available")}
                  </Tooltip>
                );
              }
            }
            return 0;
          })(),
          action: (
            <IconButton
              LinkComponent={Link}
              to={`/billboard/details?id=3`}
              disableRipple
              sx={{
                background: "#322E27",
                borderRadius: "10px",
                width: "30px",
                height: "30px",
              }}
              onClick={() =>
                dispatch(action(constants.billboardName, index?.billboardName))
              }
            >
              <SVG.EyeIcon />
            </IconButton>
          ),
        };
      })
    : []

  const columns = [
    {
      id: 1,
      name: t("Billboard_main.Billboards.tabs.billboard_address"),
      key: "billboard_address",
    },
    {
      id: 2,
      name: t("Billboard_main.Billboards.tabs.owner"),
      key: "owner",
    },
    {
      id: 3,
      name: t("Billboard_main.Billboards.tabs.offline_started"),
      key: "offline_started",
    },
    {
      id: 4,
      name: t("Billboard_main.Billboards.tabs.linked_tickets"),
      key: "linked_tickets",
    },
    {
      id: 5,
      name: t("Ad_index.commonTable.columns.actions"),
      key: "action",
    },
  ]

  const row = Array.isArray(updatedbillBoardData)
    ? updatedbillBoardData
        .filter((item) => {
          const billStatus = item.status
            ? item.status
            : item.dbStatus
            ? "online"
            : "offline"
          return billStatus === "offline"
        })
        .map((index) => {
          return {
            billboard_address: index?.address || "No data found",
            owner: index?.contactName || "No data found",
            offline_started:
              moment(index?.offlineStarted).format("DD-MMM-YYYY HH:MM") ||
              "No data found", // Add a default value if offlineStarted is falsy
            linked_tickets: (
              <Link
                to={`/ticket?id=1&billboardFilter=true`}
                onClick={() =>
                  dispatch(
                    action(constants.billboardName, index?.billboardName)
                  )
                }
              >
                {index?.linkedTickets || "No Data"}
              </Link>
            ),

            action: (
              <IconButton
                LinkComponent={Link}
                to={`/billboard/details?id=2`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
                onClick={() =>
                  dispatch(action(constants.billboardName, index?.name))
                }
              >
                <SVG.EyeIcon />
              </IconButton>
            ),
          }
        })
    : []
  return (
    <>
      <Typography
        variant="h1"
        sx={{
          fontSize: "30px",
          color: "#fff",
          fontWeight: "600",
          mb: 3,
          display: "flex",
          alignItems: "center",
          gap: "5px",
          "@media(max-width:992px)": {
            fontSize: "24px",
          },
        }}
      >
        <IconButton LinkComponent={Link} to="/dashboard">
          <SVG.ArrowRight />
        </IconButton>{" "}
        {t("Billboard_main.Billboards.title")}
      </Typography>
      <Box
        sx={{
          borderBottom: "1px solid #322E27",
          mb: 2,
          "& ul": {
            display: "flex",
            margin: "0px",
            padding: "0px",
            listStyle: "none",
            "& li": {
              color: "#fff",
              fontSize: "16px",
              padding: "16px 25px",
              cursor: "pointer",
              fontWeight: "600",
              "@media(max-width:768px)": {
                padding: "5px 25px",
              },

              borderBottom: "2px solid transparent",
              "&:hover": {
                background:
                  "linear-gradient(to right, #F7C409 0%, #B44C06 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                borderBottom: "2px solid #B44C06",
              },
            },
          },
          pointerEvents: pending ? "none" : "",
          opacity: pending ? 0.7 : 1,
        }}
        disabled={pending}
      >
        <ul>
          {userTabs.map((item) => (
            <li
              key={item.id}
              onClick={() => handleTabs(item.id)}
              style={{
                background:
                  tabs === item.id
                    ? "linear-gradient(to right, #F7C409 0%, #B44C06 100%)"
                    : "",
                WebkitBackgroundClip: tabs === item.id ? "text" : "",
                WebkitTextFillColor: tabs === item.id ? "transparent" : "",
                borderBottom: tabs === item.id ? "2px solid #B44C06 " : "",
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </Box>
      {tabs === 1 && (
        <>
          {matches ? (
            <MobileTable
              rowData={AllData}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <Box>
              <CommanTable
                columns={AllDataColumn}
                rows={AllRowData}
                currentPage={pagination.page}
                totalPages={pagination.totalPages}
                handlePageChange={(page) =>
                  setPagination((prev) => ({ ...prev, page }))
                }
                isLoading={pending}
              />
            </Box>
          )}
        </>
      )}
      {tabs === 2 && (
        <>
          {matches ? (
            <MobileTable
              rowData={updatedbillBoardData.filter((item) => {
                const billStatus = item.status
                  ? item.status
                  : item.dbStatus
                  ? "online"
                  : "offline"
                return billStatus === "offline"
              })}
              status={"offline"}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending} // || OfflineBillBoardStatus === "running"}
            />
          ) : (
            <Box>
              <CommanTable
                columns={columns}
                rows={row}
                currentPage={pagination.page}
                totalPages={pagination.totalPages}
                handlePageChange={(page) =>
                  setPagination((prev) => ({ ...prev, page }))
                }
                isLoading={pending} // || OfflineBillBoardStatus === "running"}
              />
            </Box>
          )}
        </>
      )}
      {tabs === 3 && (
        <>
          {matches ? (
            <MobileTable
              rowData={
                Array.isArray(AllData)
                  ? AllData.filter((item) => {
                      const billStatus = item.status
                        ? item.status
                        : item.dbStatus
                        ? "online"
                        : "offline"
                      return billStatus === "online"
                    })
                  : []
              }
              status={"online"}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <Box>
              <CommanTable
                columns={OnlineColumnData}
                rows={OnlineRows}
                currentPage={pagination.page}
                totalPages={pagination.totalPages}
                handlePageChange={(page) =>
                  setPagination((prev) => ({ ...prev, page }))
                }
                isLoading={pending}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}
