import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./login.css";
import { ReactComponent as Logo } from "../../icon/logo.svg";
import EmailLogin from "./component/email-login";
import PhoneLogin from "./component/phone-login";
import Language from "../../components/select-language";
import { useNavigate } from "react-router-dom";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { useTranslation } from "react-i18next";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import * as global from "../../constant/global";

export default function Login() {
  const [login, setLogin] = useState("email");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [phoneCountry, setPhoneCountry] = useState([]);

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const isRememberMe =
    localStorage.getItem(StorageHandling.storageKey.rememberMe) ||
    sessionStorage.getItem(StorageHandling.storageKey.rememberMe);

  useEffect(() => {
    // console.log("<Login>isRememberMe === true", isRememberMe === "true");
    if (isRememberMe) {
      if (isAuthenticated && isRememberMe === "true") navigate("/dashboard");
    }
    const fetchCountryList = async () => {
      const request = getRequestForApi(global.GET_COUNTRIES, methodType.GET);

      const response = await callHttpRequest(request);
      if (response.status === 200) {
        const { data } = response;

        const phoneCountries = data[0].countries?.map((country) =>
          country.countryId?.toLowerCase()
        );
        setPhoneCountry(phoneCountries || []);
      }
    };
    fetchCountryList();
  }, []);

  const handleLogin = (id) => {
    setLogin(id);
  };

  return (
    <>
      <Container>
        <Box
          sx={{ display: "flex", justifyContent: { xs: "center", lg: "end" } }}
        >
          <Language />
        </Box>
        <Box
          sx={{
            maxWidth: "520px",
            mx: "auto",
            "@media(max-width:992px)": { width: "100%" },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mb: { xs: 2, lg: 5 },
              "& svg": {
                marginTop: "25px",
                width: "424px",
                height: "120px",
                "@media(max-width:768px)": {
                  width: "150px",
                  height: "auto",
                },
              },
            }}
          >
            <Logo />
          </Box>
          <Card elevation={0} sx={{ borderRadius: "10px" }}>
            <CardContent sx={{ p: { xs: 2, lg: 5 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "rgba(34, 41, 67, 0.8)",
                  textAlign: "center",
                  mb: 3,
                  "@media(max-width:992px)": {
                    fontSize: "18px",
                    mb: 2,
                  },
                }}
              >
                {login === "email"
                  ? t("Auth_otp.login.email")
                  : t("Auth_otp.login.phone")}
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={{ xs: 2, lg: 5 }}
                sx={{ mb: { xs: 2, lg: 5 } }}
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => handleLogin("email")}
                  sx={{
                    background:
                      login === "email" &&
                      "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                    color: login === "email" && "#fff !important",
                    "@media(max-width:992px)": {
                      fontSize: "13px !important",
                      padding: "8px 18px !important",
                    },
                  }}
                >
                  {t("Auth_otp.login.email_login_button")}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => handleLogin("phone")}
                  sx={{
                    background:
                      login === "phone" &&
                      "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                    color: login === "phone" && "#fff !important",
                    "@media(max-width:992px)": {
                      fontSize: "13px !important",
                      padding: "8px 18px !important",
                    },
                  }}
                >
                  {t("Auth_otp.login.phone_login_button")}
                </Button>
              </Stack>
              {login === "email" ? (
                <EmailLogin />
              ) : (
                <PhoneLogin phoneCountry={phoneCountry} />
              )}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
